import { useContext, useState } from "react"
import QueryContext from "../../Base/Context"
import ResultTabPanelItemsTable from "../../Base/Result/TabPanels/ItemsTable"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ResultTabPanelJson from "../../Base/Result/TabPanels/Json"
import ProfileDetails from "../LinkedinProfilesSelector/ProfileDetails"
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';

export default function QueryLinkedinProfilesForCoursesResult() {
    const { response } = useContext(QueryContext)
    const [itemActive, setItemActive] = useState()

    const clickHandler = (item, column, index, itemOrig) => {
        setItemActive(itemOrig)
    }

    return <Tabs>
        <TabList>
            {response &&
                <>
                    <Tab>Hits ({response.length})</Tab>
                    <Tab>Pivot</Tab>
                </>
            }
            <Tab>JSON</Tab>
        </TabList>
        {response &&
            <>
                <TabPanel>
                    <ResultTabPanelItemsTable
                        fn_dataTransform={(response) => response}
                    />
                </TabPanel>
                <TabPanel>
                    <Pivot
                        data={response}
                    />
                </TabPanel>
            </>
        }
        <TabPanel>
            <ResultTabPanelJson />
        </TabPanel>
    </Tabs>
}

const Pivot = ({ data }) => {
    const [pivotState, setPivotState] = useState({
        rows: ['course_title', 'profile_name', 'profile_url', 'education_relevant_str'],
        aggregatorName: "Count Unique Values",
        vals: ['profile_name']
    })

    return <PivotTableUI
        data={data}
        unmountOnExit={true}
        onChange={s => setPivotState(s)}
        {...pivotState}
    />
}