import ClientProvider from '../ClientProvider';
import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react';
import DataGrid, { SortableHeaderCell, RowRendererProps, Row } from 'react-data-grid';


import { getSelectColumn } from './StatiColumn'
import { read, utils, writeFileXLSX } from 'xlsx';


var jsonElements = [
  "patterns",
  "dimensions",
  "flat",
  "output",
  "infos",
  "errors",
  "thead",
  "values"
]

const isObject = (foo) => (typeof foo === 'object' &&
  !Array.isArray(foo) &&
  foo !== null
)

const MyRowRenderer = (props) => <Row {...props} className={props.row.addClass ? props.row.addClass : ""} />

function getComparator(sortColumn) {
  if (sortColumn.includes("_values") || sortColumn.includes("_geocodes")) {
    return (a, b) => {
      const val_a = a[sortColumn] ? a[sortColumn].join(" | ") : ""
      const val_b = b[sortColumn] ? b[sortColumn].join(" | ") : ""
      return val_b.localeCompare(val_a);
    };
  }

  if (sortColumn === "priceTable_json") {
    return (a, b) => {
      if (!a[sortColumn] || !b[sortColumn])
        return 0
      const val_a = a[sortColumn].rows ? a[sortColumn].rows.length : 0
      const val_b = b[sortColumn].rows ? b[sortColumn].rows.length : 0
      return val_a - val_b;
    };
  }

  if (jsonElements.some(el => sortColumn.includes(el))) {   //see upper array
    return (a, b) => {
      let useA = a[sortColumn]
      let useB = b[sortColumn]
      if (Array.isArray(useA)) {
        useA = useA.filter(x => Object.keys(x).length)
      } else if (isObject(useA)) {
        useA = Object.keys(useA)
      }

      if (Array.isArray(useB)) {
        useB = useB.filter(x => Object.keys(x).length)
      } else if (isObject(useB)) {
        useB = Object.keys(useB)
      }

      if (typeof useA == 'number' && typeof useB == 'number') {
        return (useA) - (useB);
      }
      return (useA ? useA.length : 0) - (useB ? useB.length : 0);
    };
  }

  switch (sortColumn) {
    case '#':
    case 'path':
    case '#exec':
      return (a, b) => {
        if (a[sortColumn] === undefined || b[sortColumn] === null || b[sortColumn] === undefined || b[sortColumn] === null)
          return 0
        return sortPointDelimited(a[sortColumn], b[sortColumn])
      };
    case '#source':
      return (a, b) => {
        if (a[sortColumn] === undefined || b[sortColumn] === null || b[sortColumn] === undefined || b[sortColumn] === null)
          return 0
        return sortPointDelimited(a[sortColumn]["#"], b[sortColumn]["#"])
      };
    case 'ncalls':
    case 'count':
    case 'categories_count':
    case 'inclusion_score':
    case 'urls_count':
    case 'topic_count':
    case 'added_count':
    case 'removed_count':
    case 'count1':
    case 'count2':
    case 'countDeltaAbs':
    case 'countDeltaRel':
    case 'qs1':
    case 'qs2':
    case 'qsDeltaAbs':
    case 'qsDeltaRel':
    case 'C_diff':
    case 'C_diff%':
    case 'table_count':
    case 'table_ration':
    case 'itemCommandCount':
    case 'tottime':
    case 'percall':
    case 'cumtime':
    case 'topic_groups_set_count':
    case 'clicks':
    case 'impressions':
    case 'ctr':
    case 'pvalue':


      return (a, b) => (a[sortColumn] - b[sortColumn])
    default:
      return (a, b) => {
        return String(a[sortColumn] ? a[sortColumn] : '').localeCompare(String(b[sortColumn] ? b[sortColumn] : ''));
      };
  }
}

export function sortPointDelimited(val1, val2) {
  const val1Array = val1.split(/[_\.]+/)
  const val2Array = val2.split(/[_\.]+/)
  const depth = Math.min(val1Array.length, val2Array.length)
  var i = 0
  while (depth > i) {
    let val = val1Array[i] - val2Array[i]
    if (val !== 0)
      return val
    i = i + 1
  }
  return val1Array.length - val2Array.length
}

export function rowKeyGetter(row) {
  return Object.values(row).reduce((a, b) => a + b);
}

function selectStopPropagation(event) {
  if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
    event.stopPropagation();
  }
}

function getColumns(
  wsProvider,
  tableData,
  mergeCustomFields,
  hideColumns,
  hideColumnsContains,
  columnsSettings = {},
  urlsStatus,
  tableDataFiltered,
  filterColumns,
  setFilterColumns,
  filterFocus,
  setFilterFocus,
  filterDefinition,
  autoSortColums,
  groupedFields,
  setGroupedFields
) {

  /*
    E.g. in ItemsTable:
    filterDefinition={{
      status: {
        type: "dropdown",
        values: ["", "FAILED", "SUCCEEDED", "RUNNING"]
      }
    }}
  */
  // console.log(tableData)

  let fieldsFirstRow = tableData.length ? Object.keys(tableData[0]).filter(x => x !== 'addClass') : []
  fieldsFirstRow = [...Object.keys(columnsSettings), ...fieldsFirstRow].filter(onlyUnique);

  var use_columns = []

  if (mergeCustomFields === false) {
    use_columns = [...fieldsFirstRow]
  } else {
    const extraFields = wsProvider.CrawlerTree ? wsProvider.CrawlerTree.getCustomFields() : []
    use_columns = fieldsFirstRow.concat(extraFields).filter((v, i, a) => a.indexOf(v) === i)
  }

  if (hideColumns) {
    use_columns = use_columns.filter(col => !hideColumns.includes(col))
  }
  if (hideColumnsContains && hideColumnsContains.length > 0)
    use_columns = use_columns.filter(col => {
      var allowColumn = true
      hideColumnsContains.forEach(col_contains => {
        if (col.includes(col_contains)) {
          allowColumn = false
          return
        }
      })
      return allowColumn
    })

  use_columns = use_columns.map(x => {
    return {
      key: x,
      name: x,
      resizable: true,
      sortable: true,
      //editor: TextEditor,
      /*
      //Add Something To Cell, it was missused like this for rows
      formatter(props) {
        return <span className={props.row["addClass"]}>{props.row[x]}</span>
      },
      */
      ...(columnsSettings && columnsSettings[x] ? columnsSettings[x] : {})
    }
  })

  if (urlsStatus !== undefined) {
    use_columns = [getSelectColumn(tableDataFiltered), ...use_columns]
  }

  if (filterColumns !== undefined) {
    use_columns.filter(x => x["key"] !== "select-row").forEach(column => {
      column["headerRenderer"] = (p) => {
        let renderer = (filterDefinition && column.name in filterDefinition) ? filterDefinition[column.name]["type"] : "text"

        let rendererProps = {
          column,
          filterColumns,
          setFilterColumns,
          key: column["key"],
          filterFocus,
          setFilterFocus,
          groupedFields,
          setGroupedFields
        }

        if (renderer === "text") {
          var UseRenderer = FilterRendererText
        } else if (renderer === "dropdown") {
          var UseRenderer = FilterRendererDropdown
          rendererProps["values"] = filterDefinition[column.name]["values"]
        } else if (renderer === "none") {
          var UseRenderer = FilterRendererNone
        }
        return (
          <>
            <SortableHeaderCell {...p}>{column.name}</SortableHeaderCell>
            <UseRenderer {...rendererProps} />
          </>
        )
      }
    }
    )
  }
  if (autoSortColums === true)
    use_columns.sort((a, b) => a["key"].localeCompare(b["key"]))

  return use_columns
}

function FilterRendererText({ column, filterColumns, setFilterColumns, filterFocus, setFilterFocus, groupedFields, setGroupedFields }) {
  const key = column["key"]
  const inputRef = useRef(null);

  /* Hack to keep focus while typing */
  useEffect(() => {
    if (filterFocus === key) {
      inputRef.current.focus();
    }
  });

  return (
    <span>
      <input
        name={"filterField_" + key}
        ref={inputRef}
        value={filterColumns[key]}
        onChange={(e) => {
          const filterColumnsNew = { ...filterColumns }
          filterColumnsNew[key] = e.target.value
          setFilterFocus(key)
          setFilterColumns(filterColumnsNew)
        }}
        className="itemsTableFilter"
      />
      <input
        type="checkbox"
        checked={groupedFields.includes(key)}
        onChange={() => {
          setGroupedFields((oldList) => {
            let newList = [...oldList]
            if (oldList.includes(key)) {
              newList = [...oldList].filter(x => x !== key)
            } else {
              newList.push(key)
            }
            return newList
          })
        }}
        className="itemsTableGrouped"
        title="Group"
      />
    </span>
  );
}

function FilterRendererNone({ column, filterColumns, setFilterColumns, filterFocus, setFilterFocus }) {
  return (
    <></>
  );
}

function FilterRendererDropdown({ column, filterColumns, setFilterColumns, filterFocus, setFilterFocus, values }) {
  const key = column["key"]
  const inputRef = useRef(null);

  return (
    <>
      <select
        name="cars"
        id="cars"
        onChange={(e) => {
          const filterColumnsNew = { ...filterColumns }
          filterColumnsNew[key] = e.target.value
          setFilterColumns(filterColumnsNew)
        }}
        value={filterColumns[key]}
      >
        {
          values.map(x => (
            <option value={x} key={x}>{x}</option>
          ))
        }
      </select>
    </>
  );
}

function filterTableData(tableData, filterColumns) {
  return tableData.filter(row => {
    var isValid = true
    for (const [key, value] of Object.entries(filterColumns)) {
      if (value && key in row && !row[key].toString().includes(value)) {
        isValid = false
        return false
      }
    }
    return isValid
  })
}

function groupTableData(tableData, groupedFields) {

  let useTableDataNew = []
  let useTableDataOnce = []

  tableData.forEach(item => {
    const thiskey = groupedFields.map(key => item[key]).join("|||")
    if (useTableDataOnce.includes(thiskey))
      return
    useTableDataOnce.push(thiskey)
    useTableDataNew.push(item)
  })
  return useTableDataNew

}

//MAIN COMPONENT STARTS HERE
function ItemsTable({ tableData, mergeCustomFields, extraClass, clickHandler, hideColumns = [], hideColumnsContains, columnsSettings = {}, sortColumns, setSortColumns, urlsStatus, useRowKeyGetter, filterColumns, setFilterColumns, triggerNewOrder, filterDefinition, autoSortColums, groupedFields_default = [], noMemo = false }) {
  const wsProvider = React.useContext(ClientProvider);
  const [filterFocus, setFilterFocus] = useState()
  const [groupedFields, setGroupedFields] = useState(groupedFields_default)
  const [pres, setPres] = useState([]);

  const tableDataGrouped = groupedFields.length > 0 ? groupTableData(tableData, groupedFields) : tableData

  const tableDataFiltered = filterColumns !== undefined ? filterTableData(tableDataGrouped, filterColumns) : tableDataGrouped

  const exportFileXlsx = useCallback((data) => {
    const columns = getColumns(wsProvider, tableData, mergeCustomFields, hideColumns, hideColumnsContains, columnsSettings, urlsStatus, tableDataFiltered, filterColumns, setFilterColumns, filterFocus, setFilterFocus, filterDefinition, autoSortColums, groupedFields, setGroupedFields)

    const ws = utils.json_to_sheet(data, { header: columns.map(x => x.key) });
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFileXLSX(wb, "SheetJSReactAoO.xlsx");
  }, [pres]);

  const exportFileJson = useCallback((data) => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(data, null, 2)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "data.json";

    link.click();
  }, [pres]);

  if (tableData === undefined || tableData.length === 0)
    return null



  const columns = getColumns(wsProvider, tableData, mergeCustomFields, hideColumns, hideColumnsContains, columnsSettings, urlsStatus, tableDataFiltered, filterColumns, setFilterColumns, filterFocus, setFilterFocus, filterDefinition, autoSortColums, groupedFields, setGroupedFields)

  const sortedRows = () => {
    if (sortColumns.length === 0) return tableDataFiltered;

    const sortedRows = [...tableDataFiltered];
    sortedRows.sort((a, b) => {
      for (const sort of sortColumns) {

        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
    return sortedRows;
  };

  const rowsSorted = sortedRows()

  const sortedRowsPrep = () => {
    let results = []
    let rowCount = 0
    rowsSorted.forEach(row => {

      let row2 = {}
      for (let key of Object.keys(row)) {
        if (Array.isArray(row[key]) && row[key] !== undefined) {
          if (key.includes("_values")) {
            row2[key] = row[key].join(" | ")
          } else if (key === "location")
            row2[key] = "[" + row[key].join(", ") + "]"
          else
            row2[key] = "[" + row[key].filter(x => Object.keys(x).length).length + "]"
        }
        else if (typeof row[key] === 'object' &&
          !Array.isArray(row[key]) &&
          row[key] !== null
        ) {
          if (key === "#source" && row[key] && "#" in row[key]) {
            row2[key] = row[key]["#"] ? row[key]["crawlerI"] + "_" + row[key]["#"] : ""
          } else if (key === "priceTable_json") {
            row2[key] = "[" + (row[key].rows ? row[key].rows.length : 0) + "]"
          } else {
            row2[key] = "[" + Object.keys(row[key]).length + "]"
          }
        }
        else
          row2[key] = row[key]
      }
      results.push(row2)
      rowCount = rowCount + 1
    });
    return results
  };

  const clickHandler2 = (index, item, column) => {

    if (column["key"] === "url" || column["key"] === "cache_url") {
      //alert(item[column["key"]].replace(/\\/gi, "/"))
      window.open(item[column["key"]].replace(/\\/gi, "/"), '_blank');
    }
    if (clickHandler) {
      clickHandler(item, column["key"], index, rowsSorted[index], columns.findIndex(col => col["key"] === column["key"]))
    }
  }

  const sortedRowsPreped = sortedRowsPrep()

  if (triggerNewOrder !== undefined) {
    triggerNewOrder(sortedRowsPreped, rowsSorted)
  }

  return (
    <div className={"row height100 dataGridWrapper " + extraClass}>
      <div className={"col-12"}>
        <DataGrid
          rowKeyGetter={useRowKeyGetter ? useRowKeyGetter : rowKeyGetter}
          rowHeight={25}
          headerRowHeight={filterColumns !== undefined ? 60 : undefined}
          columns={columns}
          rows={sortedRowsPreped}
          //onRowsChange={setRows}
          sortColumns={sortColumns}
          onSortColumnsChange={setSortColumns}
          className="dataGrid1, rdg-dark"
          onRowClick={clickHandler2}
          rowRenderer={MyRowRenderer}
        />
      </div>
      <button onClick={() => exportFileXlsx(sortedRowsPreped)} className="btn btn-primary btn-sm btn-download-xlsx">
        <i className="bi bi-download"></i> xlsx
      </button>
      <button onClick={() => exportFileJson(sortedRowsPreped)} className="btn btn-primary btn-sm btn-download-json">
        <i className="bi bi-download"></i> json
      </button>
    </div>
  )
}

function unequalTwoValues(val1, val2) {
  return JSON.stringify(val1) !== JSON.stringify(val2)
}
function areEqual(prevProps, nextProps) {
  if (nextProps.noMemo === true) {
    return false
  }
  /*
    tableData={props.tableData}
    mergeCustomFields={props.mergeCustomFields}   //fixed input [boolean]
    extraClass={props.extraClass}                 //fixed input [string]
    clickHandler={clickHandler}                   //fixed input [function] [not 100% sure)]
    hideColumns={props.hideColumns}               //fixed input [array]
    columnsSettings={props.columnsSettings}       //fixed input [object]
    sortColumns={props.sortColumns}               //DYNAMIC
    showAll={props.sortColumns}                   //DYNAMIC
    execSelected={props.sortColumns}              //DYNAMIC
    setSortColumns={props.setSortColumns}         //fixed input [function]
  */
  if (unequalTwoValues(prevProps["sortColumns"], nextProps["sortColumns"])) {
    return false
  }
  if (unequalTwoValues(prevProps["time"], nextProps["time"])) {
    return false
  }
  if (unequalTwoValues(prevProps["tableData"].length, nextProps["tableData"].length)) {
    return false
  }
  if (unequalTwoValues(prevProps["showAll"], nextProps["showAll"])) {
    return false
  }
  if (unequalTwoValues(prevProps["execSelected"], nextProps["execSelected"])) {
    return false
  }
  if (unequalTwoValues(prevProps["urlsStatus"], nextProps["urlsStatus"])) {
    return false
  }
  if (unequalTwoValues(prevProps["filterColumns"], nextProps["filterColumns"])) {
    return false
  }
  const col1 = getColumns(prevProps["wsProvider"], prevProps["tableData"], prevProps["mergeCustomFields"], prevProps["hideColumns"], prevProps["columnsSettings"], undefined)
  const col2 = getColumns(nextProps["wsProvider"], nextProps["tableData"], nextProps["mergeCustomFields"], nextProps["hideColumns"], nextProps["columnsSettings"], undefined)
  if (unequalTwoValues(col1, col2)) {
    return false
  }
  return true
}
export default React.memo(ItemsTable, areEqual);
//export default ItemsTable;

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
