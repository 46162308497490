import { useContext } from 'react';
import QueryContext from '../../Base/Context';
import ClientProvider from '../../../../Various/ClientProvider';


export default function RunButton({ }) {
    const wsProvider = useContext(ClientProvider);
    const { setResponse, setResponseTimestamp, formData } = useContext(QueryContext)

    const run_query = () => {
        wsProvider.ws.waitForSocketAndSend({
            action: "get_report_stats",
            options: {
                formData
            }
        }, (response) => {
            setResponseTimestamp(Date.now())
            setResponse(response)
        })
    }

    return <button
        onClick={run_query}
        className="btn btn-primary btn-md"
        style={{ "width": "100%", marginTop: "10px" }}
        type="button"
    >
        Run
    </button>
}