import Form from "@rjsf/core";
import monacoWidgetJson from "../../../../Various/rjsf/monacoWidgetJson";
import QueryContext from "../Context";
import { useContext, useEffect } from "react";

const widgets = {
    monacojson: monacoWidgetJson
}

export default function ConfigForm({ schema, uiSchema, className, generate_query, defaultFormData }) {
    const { setQuery, formData, setFormData } = useContext(QueryContext)

    useEffect(() => {
        if (generate_query) {
            try {
                const query = generate_query()
                setQuery(query)
            } catch (error) {
                // console.error(error);
            }
        }
    }, [JSON.stringify(formData)])

    useEffect(() => {
        setFormData(defaultFormData())
    }, [])

    const onChange = ({ formData }) => {
        console.log(formData)
        setFormData(formData)
    }

    return <Form
        className={className}
        formData={formData}
        onChange={onChange}
        widgets={widgets}
        schema={schema}
        uiSchema={uiSchema}
    >
        <button className="btn btn-info" style={{ "display": "none" }}>Load</button>
    </Form>
}