import { useContext, useEffect, useState } from "react";
import ConfigWrapper from "../../Base/Config/ConfigWrapper";
import ConfigForm from "../../Base/Config/ConfigForm";
import IndexSelector from "../../Base/Config/IndexSelector";
import { propertiesProvince, propertiesSize, propertiesSource } from "../../Base/Config/formDataConstants";
import RunButton from "./RunButton";

export default function QueryLinkedinProfilesForCoursesConfig() {
    return <ConfigWrapper
    // defaultIndexName="tracking"  # multiple indices needed
    >
        <ConfigForm
            schema={{
                "type": "object",
                "properties": {
                    group_by: {
                        "type": "array",
                        "title": "group_by",
                        "items": {
                            "type": "string",
                            "enum": ["redirect_url", "landing_page", "client_id"],
                        },
                        "uniqueItems": true
                    },
                    client_ids: {
                        "type": "array",
                        "title": "client_ids",
                        "items": {
                            "type": "string",
                            "enum": ["swissaccounting-org", "shl-ch", "klubschule-pro-ch", "ost-ch", "zhaw-ch"],
                        },
                        "uniqueItems": true
                    },
                    "date_from": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "date_to": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "has_no_campaign": {
                        "type": "string",
                        "title": "has_no_campaign (clicks)",
                        "enum": [true, false]
                    },
                    gad_sources: {
                        "type": "array",
                        "items": {
                            "type": "string",
                            "enum": ["", "1", "linkedIn"],
                            "enumNames": ["empty", "1 (Google Ads)", "linkedIn"],
                        },
                        "uniqueItems": true
                    },
                    hide_fields: {
                        "type": "array",
                        "items": {
                            "type": "string",
                            "enum": ["title", "degree", "url", "bucket_key", "item_id", "impressions", "clicks", "ctr", "pvalue", "client_id"],
                        },
                        "uniqueItems": true
                    },
                }

            }}
            uiSchema={{
                "group_by": {
                    "ui:widget": "checkboxes"
                },
                "client_ids": {
                    "ui:widget": "checkboxes"
                },
                "gad_sources": {
                    "ui:widget": "checkboxes"
                },
                "hide_fields": {
                    "ui:widget": "checkboxes"
                },
                'ui:order': [
                    "group_by",
                    "client_ids",
                    "date_from",
                    "date_to",
                    "has_no_campaign",
                    "gad_sources",
                    "hide_fields"
                ]
            }}
            defaultFormData={() => ({
                group_by: ["client_id", "redirect_url"],
                client_ids: ["ost-ch"],
                date_from: "2024-12-31T23:00:00.000Z",
                date_to: "2025-01-31T23:00:00.000Z",
                has_no_campaign: "false",
                gad_sources: [],
                hide_fields: ["pvalue", "item_id", "bucket_key"]
            })}
            className="rjsf_QueryLinkedinProfilesSelectorConfig"
        />
        <RunButton />
    </ConfigWrapper>
}