import Header from "./QuerySelector"
import { useState } from "react";
import BaseQuery from "../Base/BaseQuery";
import QueryLinkedinProfilesSelectorConfig from "../Queries/LinkedinProfilesSelector/Config";
import QueryLinkedinProfilesSelectorResult from "../Queries/LinkedinProfilesSelector/Result";
import QueryLinkedinProfilesForCoursesConfig from "../Queries/LinkedinProfilesForCourses/Config";
import QueryLinkedinProfilesForCoursesResult from "../Queries/LinkedinProfilesForCourses/Result";
import QueryRawConfig from "../Queries/Raw/Config";
import QueryRawResult from "../Queries/Raw/Result";
import QueryCoursesByTopicGroupConfig from "../Queries/CoursesByTopicGroup/Config";
import QueryCoursesByTopicGroupResult from "../Queries/CoursesByTopicGroup/Result";
import QueryRInderDishesSelectorConfig from "../Queries/rInderDishes/Config";
import QueryRInderDishesSelectorResult from "../Queries/rInderDishes/Result";

import PerformanceStatsConfig from "../Queries/PerformanceStats/Config";
import PerformanceStatsResult from "../Queries/PerformanceStats/Result";

export default function WorkspaceESQuery() {
    const queriesToSelect = [
        { "value": "linkedinProfilesForCourses", label: "linkedinProfilesForCourses" },
        { "value": "linkedInProfilesSelector", label: "linkedInProfilesSelector" },
        { "value": "coursesByTopicGroup", label: "coursesByTopicGroup" },
        { "value": "rInderDishes", label: "rInderDishes" },
        { "value": "performanceStats", label: "performanceStats" },
        { "value": "raw", label: "Raw" }
    ]
    const [selectedQuery, setSelectedQuery] = useState(queriesToSelect[0])

    return (<>
        <div className="row">
            <div className="col-12 workspaceTop ">
                <div className='flex_header' style={{ height: "calc(100% - 30px)" }}>
                    <div className='header'>
                        <div className='row' style={{ marginBottom: "10px" }}>
                            <div className='col-4'>
                                <div className='row' style={{ marginBottom: "10px" }}>
                                    <Header
                                        queriesToSelect={queriesToSelect}
                                        selectedQuery={selectedQuery}
                                        setSelectedQuery={setSelectedQuery}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='content'>
                        <div className='row height100 '>
                            {selectedQuery.value === "raw" &&
                                <BaseQuery
                                    config={<QueryRawConfig />}
                                    result={<QueryRawResult />}
                                />
                            }
                            {selectedQuery.value === "linkedInProfilesSelector" &&
                                <BaseQuery
                                    config={<QueryLinkedinProfilesSelectorConfig />}
                                    result={<QueryLinkedinProfilesSelectorResult />}
                                />
                            }
                            {selectedQuery.value === "linkedinProfilesForCourses" &&
                                <BaseQuery
                                    config={<QueryLinkedinProfilesForCoursesConfig />}
                                    result={<QueryLinkedinProfilesForCoursesResult />}
                                />
                            }
                            {selectedQuery.value === "coursesByTopicGroup" &&
                                <BaseQuery
                                    config={<QueryCoursesByTopicGroupConfig />}
                                    result={<QueryCoursesByTopicGroupResult />}
                                />
                            }
                            {selectedQuery.value === "rInderDishes" &&
                                <BaseQuery
                                    config={<QueryRInderDishesSelectorConfig />}
                                    result={<QueryRInderDishesSelectorResult />}
                                />
                            }
                            {selectedQuery.value === "performanceStats" &&
                                <BaseQuery
                                    config={<PerformanceStatsConfig />}
                                    result={<PerformanceStatsResult />}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}